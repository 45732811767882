import { getCurrentUser, initAuth } from "../../clients/firebase";
const headers = {
  "Content-Type": "application/json",
};

class DefaultService {
  public collection: string;
  protected baseAPI = process.env.REACT_APP_ERP_BACKEND;

  constructor(collection: string) {
    this.collection = collection;
  }
  intercept(url: string, config: any): any {
    const user: any = getCurrentUser();
    if (user && user?.accessToken) {
      // get user session profile from profile service
      let _token = user.accessToken;
      let _config = { ...config }; // shallow copy of the config
      _config.headers = _config.headers || {}; // make sure headers is initialized
      _config.headers["authorization"] = _token;

      return [url, _config];
    } else {
      const firebaseAuth = initAuth();
      firebaseAuth.signOut();
    }
  }
  async fetchJson<T>(endPoint: string, options?: RequestInit): Promise<T> {
    return new Promise((resolve, reject) => {
      const [url, config] = this.intercept(
        `${this.baseAPI}/${this.collection}${endPoint}`,
        {
          ...options,
          headers: {
            ...headers,
            ...(options?.headers || {}),
          },
        },
      );
      fetch(url, config)
        .then((res) => {
          if (res.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  async uploadFile(endPoint: string, file: File): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const [url, config] = this.intercept(
      `${this.baseAPI}/${this.collection}${endPoint}`,
      {
        method: "POST",
        body: formData,
        // Note: Fetch will set the Content-Type header to 'multipart/form-data' with the appropriate boundary automatically.
      },
    );

    return new Promise((resolve, reject) => {
      fetch(url, config)
        .then((res) => {
          checkStatusAction(res.status);

          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  async uploadManyFiles(endPoint: string, files: File[]): Promise<any> {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    const [url, config] = this.intercept(
      `${this.baseAPI}/${this.collection}${endPoint}`,
      {
        method: "POST",
        body: formData,
        // Note: Fetch will set the Content-Type header to 'multipart/form-data' with the appropriate boundary automatically.
      },
    );

    return new Promise((resolve, reject) => {
      fetch(url, config)
        .then((res) => {
          checkStatusAction(res.status);
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  async fetchNormal(endPoint: string, options?: RequestInit): Promise<any> {
    return new Promise((resolve, reject) => {
      const [url, config] = this.intercept(
        `${this.baseAPI}/${this.collection}${endPoint}`,
        {
          ...options,
          headers: {
            ...headers,
            ...(options?.headers || {}),
          },
        },
      );
      fetch(url, config)
        .then((res) => {
          checkStatusAction(res.status);
          if (res.ok) {
            return res;
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  async uploadSingleFile(endPoint: string, file: File): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const [url, config] = this.intercept(
      `${this.baseAPI}/${this.collection}${endPoint}`,
      {
        method: "POST",
        body: formData,
        // Note: Fetch will set the Content-Type header to 'multipart/form-data' with the appropriate boundary automatically.
      },
    );

    return new Promise((resolve, reject) => {
      fetch(url, config)
        .then((res) => {
          checkStatusAction(res.status);
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
}

function checkStatusAction(status: number) {
  if (status === 401) {
    const firebaseAuth = initAuth();
    firebaseAuth.signOut();
  }

  return;
}

export default DefaultService;
